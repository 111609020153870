import React from "react";

import { Link, Section } from "@Components/blocks";
import { Page } from "@Components/layout";

const NotFoundPage = () => {
  return (
    <Page>
      <Section align="left" background="white">
        <h1>Oops! Looks like you took a wrong turn!</h1>
        <Link kind="primary" to="/">
          Go home
        </Link>
      </Section>
    </Page>
  );
};

export default NotFoundPage;
